import React from "react";
import "../Navbar/Navbar.css";
import { NavLink } from "react-router-dom";
import KeycloakService from "../../api/KeyCloack";

const Navbar = ({toggle}) => {
  return (
    <div className="nav">
      <div className="navbarContainer">
        <div style={{display:"flex", flexDirection:"column", justifyContent:"left"}}>
          <h2 style={{marginLeft:"10px"}}>Dashboard</h2>
          <h5 style={{marginLeft:"10px"}}>JTV Analytics</h5>
        </div>

        <div>
          <ul className="navMenu">
            <li><NavLink className="navbarLink" activeStyle={{ backgroundColor:'#08d1d8', color:'black' }} to="/user">Users</NavLink></li>
            <li><NavLink className="navbarLink" activeStyle={{ backgroundColor:'#08d1d8', color:'black' }} to="/" exact>Releases</NavLink></li>
            <li><NavLink className="navbarLink" activeStyle={{ backgroundColor:'#08d1d8', color:'black' }} to="/delivered">Delivered Releases</NavLink></li>
            <li><NavLink className="navbarLink" activeStyle={{ backgroundColor:'#08d1d8', color:'black' }} to="/catalog">Catalog</NavLink></li>
          </ul>
        </div>
         
        <div className="mobileIcon">
          <ion-icon onClick={toggle} name="menu-outline"></ion-icon>
        </div>
        <button className="logoutButton" onClick={KeycloakService.logout}>
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default Navbar;
