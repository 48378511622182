import React from 'react'
import { FaTimes } from 'react-icons/fa'
import './Sidebar.css'
import { NavLink } from "react-router-dom"
import KeycloakService from "../../api/KeyCloack";

const Sidebar = ({isOpen, toggle}) => {
  return (
    <aside className='sidebarContainer' isOpen={isOpen} onClick={toggle}>
        <div className='closeIcon'>
          <FaTimes />
        </div>
        <ul className='SidebarLinksContainer'>
          <NavLink className='SidebarLink' to="/user">User</NavLink>
          <NavLink className='SidebarLink' to="/release">Releases</NavLink>
          <NavLink className='SidebarLink' to="/delivered">Delivered Releases</NavLink>
          <NavLink className='SidebarLink' to="/catalog">Catalog</NavLink>
        </ul>
        <div className='SideButtonWrap'>
          <button className='SideButton' onClick={KeycloakService.logout}>Sign Out</button>
        </div>
    </aside>
  )
}

export default Sidebar