import Keycloak from "keycloak-js";

let _kc;
let env;
const initKeycloak = (onAuthenticatedCallback, unauthorizedApp) => {
    _kc = new Keycloak({
        url: "https://pp-sso.trace.tv/auth",
        realm: "Trace",
        clientId: "jtv-web"
    });
    _kc.init({
        useNonce: false,
        pkceMethod: 'S256',
        onLoad: 'login-required',
        checkLoginIframe: false
    })
        .then((authenticated) => {
            if (authenticated) {
                onAuthenticatedCallback();

            } else {
                unauthorizedApp();
            }
        });
};

const expiresIn = () => {
    let date = new Date(_kc.tokenParsed?.exp * 1000);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

const environment = () => env;

const login = () => _kc.login;

const logout = () => _kc.logout();

const getToken = () => _kc.token;

const getBuCode = () => _kc.tokenParsed.bu;

const isLoggedIn = () => !!_kc.token;

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const loadUserInfo = () => _kc.loadUserInfo();

const loadUserProfile = () => _kc.loadUserProfile();

const authenticated = () => _kc.authenticated;

const updateToken = (successCallback) =>
    _kc.updateToken(3600)
        .then(successCallback)
        .catch(login);

const roles = () => _kc.realmAccess.roles;

const KeycloakService = {
    environment,
    roles,
    getBuCode,
    authenticated,
    loadUserInfo,
    loadUserProfile,
    login,
    logout,
    initKeycloak,
    isLoggedIn,
    updateToken,
    getToken,
    getUsername,
    hasRole,
    expiresIn
};

export default KeycloakService;
