const API_URL = "/api/";

class Api {

    fetch(uri, method, body) {
        let headers = new Headers();
        headers.set("Content-Type", "application/json");
        return fetch(API_URL + uri, { headers: headers, method: method, body: body === null ? null : JSON.stringify(body) })
    }

    upload(uri, file) {
        let formData = new FormData();
        formData.append("file", file);
        return fetch(API_URL + uri, { method: "post", body: formData })
    }

    get(uri) {
        return this.fetch(uri, "get", null).then(r => r.json());
    }

    post(uri, body) {
        return this.fetch(uri, "post", body).then(r => r.json());
    }
}

export default new Api();