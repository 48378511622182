import React, { useState, useEffect, useRef } from "react";
import Highcharts from 'highcharts/highstock';
import {
    HighchartsProvider, HighchartsChart, Chart, XAxis, YAxis, Title, LineSeries, Legend, ColumnSeries
} from 'react-jsx-highcharts';
import Api from "../../api/Api";
import './Delivered.css';

export default function Delivered() {
    const [releasesByYear, setReleasesByYear] = useState(null);
    const [releasesByMonth, setReleasesByMonth] = useState(null);
    const [releasesByWeek, setReleasesByWeek] = useState(null);
    const [startYear, setStartYear] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isFetching, setFetching] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const inputFile = useRef(null);

    const [showRelease, setShowRelease] = useState(false);
    const [showSales, setShowSales] = useState(false);
    const [showAll, setShowAll] = useState(true);

    useEffect(() => {
        const fetchData = () => {
            Api.get("delivered/year/_search")
                .then(releasesByYear => {
                    if (Object.keys(releasesByYear).length > 0) {
                        setStartYear(Number(Object.entries(releasesByYear).map(e => e[0]).shift()));
                        setReleasesByYear(releasesByYear);
                    }
                });

            Api.get("delivered/month/_search")
                .then(releasesByMonth => {
                    if (Object.keys(releasesByMonth).length > 0) {
                        setReleasesByMonth(releasesByMonth);
                    }
                });

            Api.get("delivered/week/_search")
                .then(releasesByWeek => {
                    if (Object.keys(releasesByWeek).length > 0) {
                        setReleasesByWeek(releasesByWeek);
                    }
                });
        }

        if (isFetching) {
            fetchData();
            setFetching(false);
        }

    }, [isFetching]);

    const plotOptions = {
        series: {
            pointStart: startYear
        },
        pie: {
            dataLabels: {
                enabled: true,
                format: '{point.name} : {point.percentage:.1f} %',
            }
        }
    };
    const tooltip = {
        pointFormat: '{series.name}: {point.percentage:.1f} <br>{point.y}'
    }

    const monthCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const weekCategories = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53];


    const selectFile = (event) => {
        setSelectedFiles(event.target.files);
    };

    const upload = () => {
        setLoading(true);
        Api.upload("csv/delivered/upload", selectedFiles[0])
            .then(() => { setSelectedFiles(null); setFetching(true); setLoading(false) });
    }

    function setFilters(clickedId) {
        console.log(clickedId);
        if (clickedId === "releases") {
          setShowRelease(true);
          setShowSales(false);
          setShowAll(false)
        } else if (clickedId === "sales") {
            setShowRelease(false);
            setShowSales(true);
            setShowAll(false)
        } else if (clickedId === "all") {
            setShowRelease(false);
            setShowSales(false);
            setShowAll(true)
        }
    }
  
      const handleClick = e => setFilters(e.target.id);


    return (
        <>
            
            <div className="filterMenuContainer">
                <div className="menuItemWrapper">
                        <button id="all" className={showAll ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Show All</button>
                        <button id="releases" className={showRelease ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Releases</button>
                        <button id="sales" className={showSales ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Sales</button>
                </div>
            <div className="importUploadButtonsContainer" style={{background:"#08d1d8"}}>
                <input type="file" ref={inputFile} style={{ display: "none" }} onChange={selectFile} />
                {!isLoading && <button title="Choose File" className="importUploadButton" onClick={() => inputFile.current.click()}><ion-icon style={{pointerEvents:"none"}} name="folder"></ion-icon></button>}
                {!isLoading && <button title="Upload" className="importUploadButton" disabled={!selectedFiles} onClick={upload}><ion-icon style={{pointerEvents:"none"}} name="cloud-upload"></ion-icon></button>}
                {isLoading && <img src='../../Loading_icon.gif' alt="" />}
            </div>
            </div>

            {!isLoading && (showRelease || showAll) &&
            <div className="chartsContainer">
                <div>
                    {releasesByYear && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart plotOptions={plotOptions} tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Releases by year</Title>
                            <XAxis>
                                <XAxis.Title>Year</XAxis.Title>
                            </XAxis>
                            <YAxis id="number">
                                <YAxis.Title>User count</YAxis.Title>
                                <LineSeries id="my-series" data={Object.entries(releasesByYear).map(e => e[1].length)} />
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>
                <div>
                    {releasesByMonth && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Releases by month</Title>
                            <Legend />
                            <XAxis categories={monthCategories}>
                                <XAxis.Title>Month</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(releasesByMonth))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].length)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>
                <div>
                    {releasesByWeek && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Releases by week</Title>
                            <Legend />
                            <XAxis categories={weekCategories}>
                                <XAxis.Title>Week</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(releasesByWeek))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].length)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>
                </div>}
                

                {!isLoading && (showSales || showAll) &&
                <div className="chartsContainer">
                <div>
                    {releasesByYear && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart plotOptions={plotOptions} tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Sales by year</Title>
                            <XAxis>
                                <XAxis.Title>Year</XAxis.Title>
                            </XAxis>
                            <YAxis id="number">
                                <YAxis.Title>Total sales</YAxis.Title>
                                <LineSeries id="my-series" data={Object.entries(releasesByYear).map(e => e[1].reduce((a, b) => ({ salesPayout: a.salesPayout + b.salesPayout }))).map(e => e.salesPayout)} />
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>
                <div>
                    {releasesByMonth && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Sales by month</Title>
                            <Legend />
                            <XAxis categories={monthCategories}>
                                <XAxis.Title>Month</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(releasesByMonth))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].reduce((a, b) => ({ salesPayout: a.salesPayout + b.salesPayout }))).map(e => e.salesPayout)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>
                <div>
                    {releasesByWeek && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Sales by week</Title>
                            <Legend />
                            <XAxis categories={weekCategories}>
                                <XAxis.Title>Week</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(releasesByWeek))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].reduce((a, b) => ({ salesPayout: a.salesPayout + b.salesPayout }))).map(e => e.salesPayout)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>
            </div>}
        </>
    );
}