import React, { useState, useEffect, useRef } from "react";
import Highcharts from 'highcharts/highstock';
import {
    HighchartsProvider, HighchartsChart, Chart, XAxis, YAxis, Title, LineSeries, PieSeries, Legend, ColumnSeries
} from 'react-jsx-highcharts';
import Api from "../../api/Api";
import './User.css'
import '../filterMenu/FilterMenu.css'


export default function User() {

    const [usersByKnownCountry, setUsersByKnownCountry] = useState(null);
    const [usersByCountry, setUsersByCountry] = useState(null);
    const [usersByActive, setUsersByActive] = useState(null);
    const [usersByYear, setUsersByYear] = useState(null);
    const [usersByMonth, setUsersByMonth] = useState(null);
    const [usersByWeek, setUsersByWeek] = useState(null);
    const [startYear, setStartYear] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isFetching, setFetching] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const inputFile = useRef(null);

    //const [showAll, setShowAll] = useState(true)
    const [showUsers, setShowUsers] = useState(false)
    const [showExports, setShowExports] = useState(false)
    const [showReleases, setShowReleases] = useState(false)
    const [showTracks, setShowTracks] = useState(false)
    const [showAll, setShowAll] = useState(true);

    useEffect(() => {
        const fetchData = () => {
            Api.get("user/year/_search")
                .then(usersByYear => {
                    if (Object.keys(usersByYear).length > 0) {
                        setStartYear(Number(Object.entries(usersByYear).map(e => e[0]).shift()));
                        setUsersByYear(usersByYear);
                    }
                });

            Api.get("user/month/_search")
                .then(usersByMonth => {
                    if (Object.keys(usersByMonth).length > 0) {
                        setUsersByMonth(usersByMonth);
                    }
                });

            Api.get("user/week/_search")
                .then(usersByWeek => {
                    if (Object.keys(usersByWeek).length > 0) {
                        setUsersByWeek(usersByWeek);
                    }
                });

            Api.get("user/country/known/_search")
                .then(usersByKnownCountry => {
                    if (Object.keys(usersByKnownCountry).length > 0) {
                        setUsersByKnownCountry((Object.entries(usersByKnownCountry).map(o => {
                            var peiEntry = {};
                            peiEntry['name'] = o[0];
                            peiEntry['y'] = o[1].length;
                            return peiEntry;
                        })));
                    }
                });

            Api.get("user/country/_search")
                .then(usersByCountry => {
                    if (Object.keys(usersByCountry).length > 0) {
                        setUsersByCountry((Object.entries(usersByCountry).map(o => {
                            var peiEntry = {};
                            peiEntry['name'] = o[0];
                            peiEntry['y'] = o[1].length;
                            return peiEntry;
                        })));
                    }
                });

            Api.get("user/active/_search")
                .then(usersByActive => {
                    if (Object.keys(usersByActive).length > 0) {
                        setUsersByActive((Object.entries(usersByActive).map(o => {
                            var peiEntry = {};
                            peiEntry['name'] = o[0];
                            peiEntry['y'] = o[1].length;
                            return peiEntry;
                        })));
                    }
                });
        }

        if (isFetching) {
            fetchData();
            setFetching(false);
        }
    }, [isFetching]);

    const plotOptions = {
        series: {
            pointStart: startYear
        },
        pie: {
            dataLabels: {
                enabled: true,
                format: '{point.name} : {point.percentage:.1f} %',
            }
        },
    };
    const tooltip = {
        pointFormat: '{series.name}: {point.percentage:.1f} <br>{point.y}'
    }

    const monthCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const weekCategories = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53];


    const selectFile = (event) => {
        setSelectedFiles(event.target.files);
    };

    const upload = () => {
        setLoading(true);
        Api.upload("csv/user/upload", selectedFiles[0])
            .then(() => { setSelectedFiles(null); setFetching(true); setLoading(false) });
    }

    
   

    function setFilters(clickedId) {
        console.log(clickedId)
        if (clickedId === "users") {
            setShowUsers(true);
            setShowReleases(false);
            setShowExports(false);
            setShowTracks(false);
            setShowAll(false)
        } else if (clickedId === "exports") {
            setShowUsers(false);
            setShowReleases(false);
            setShowExports(true);
            setShowTracks(false);
            setShowAll(false)
        } else if (clickedId === "releases") {
            setShowUsers(false);
            setShowReleases(true);
            setShowExports(false);
            setShowTracks(false);
            setShowAll(false)
        } else if (clickedId === "tracks") {
            setShowUsers(false);
            setShowReleases(false);
            setShowExports(false);
            setShowTracks(true);
            setShowAll(false)
        } else if (clickedId === "all") {
            setShowUsers(false);
            setShowReleases(false);
            setShowExports(false);
            setShowTracks(false);
            setShowAll(true)
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    }

    const handleClick = e => setFilters(e.target.id);


    return (
        <>
            <div className="filterMenuContainer">
                <div className="menuItemWrapper">
                    <button id="all" className={showAll ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Show All</button>
                    <button id="users" className={showUsers ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Users</button>
                    <button id="exports" className={showExports ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Exports</button>
                    <button id="releases" className={showReleases? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Releases</button>
                    <button id="tracks" className={showTracks ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Tracks</button>
                </div>

                <div className="importUploadButtonsContainer">
                    <input type="file" ref={inputFile} style={{ display: "none" }} onChange={selectFile} />
                    {!isLoading && <button title="Choose File" className="importUploadButton" onClick={() => inputFile.current.click()}><ion-icon style={{pointerEvents:"none"}} name="folder"></ion-icon></button>}
                    {!isLoading && <button title="Upload" className="importUploadButton" disabled={!selectedFiles} onClick={upload}><ion-icon style={{pointerEvents:"none"}} name="cloud-upload"></ion-icon></button>}
                    {isLoading && <img src='../../Loading_icon.gif' alt="" />}
                </div>
            </div>

            
            {!isLoading && (showUsers || showAll) &&
            <div className="chartsContainer">   
                    {usersByKnownCountry && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" plotOptions={plotOptions}>
                            <Title style={{fontSize:"1.3rem"}}>User & Country</Title>
                            <PieSeries data={usersByKnownCountry} />
                            <Chart height={500}/>
                        </HighchartsChart>
                    </HighchartsProvider>}             

                    {usersByCountry && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" plotOptions={plotOptions}>
                            <PieSeries data={usersByCountry} />
                            <Chart height={500}/>
                        </HighchartsChart>
                    </HighchartsProvider>}
                
                    {usersByActive && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" plotOptions={plotOptions}>
                            <Title style={{fontSize:"1.3rem"}}>User & Active</Title>
                            <PieSeries data={usersByActive} />
                            <Chart height={500}/>
                        </HighchartsChart>
                    </HighchartsProvider>}
                
                    {usersByYear && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" plotOptions={plotOptions} tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>User by year</Title>
                            <XAxis>
                                <XAxis.Title>Year</XAxis.Title>
                            </XAxis>
                            <YAxis id="number">
                                <YAxis.Title>User count</YAxis.Title>
                                <LineSeries id="my-series" name="users" data={Object.entries(usersByYear).map(e => e[1].length)} />
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}

                    {usersByMonth && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>User by month</Title>
                            <Legend />
                            <XAxis categories={monthCategories}>
                                <XAxis.Title>Month</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(usersByMonth))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].length)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}

                    {usersByWeek && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>User by week</Title>
                            <Legend />
                            <XAxis categories={weekCategories}>
                                <XAxis.Title>Week</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(usersByWeek))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].length)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>}

                {!isLoading && (showExports || showAll) &&
                 <div className="chartsContainer">
                    {usersByYear && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" plotOptions={plotOptions} tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Exports by year</Title>
                            <XAxis>
                                <XAxis.Title>Year</XAxis.Title>
                            </XAxis>
                            <YAxis id="number">
                                <YAxis.Title>Exports count</YAxis.Title>
                                <LineSeries id="my-series" name="exports" data={Object.entries(usersByYear).map(e => e[1].reduce((a, b) => ({ exports: a.exports + b.exports }))).map(e => e.exports)} />
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}

                    {usersByMonth && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Exports by month</Title>
                            <Legend />
                            <XAxis categories={monthCategories}>
                                <XAxis.Title>Month</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(usersByMonth))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].reduce((a, b) => ({ exports: a.exports + b.exports }))).map(e => e.exports)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}

                    {usersByWeek && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Exports by week</Title>
                            <Legend />
                            <XAxis categories={weekCategories}>
                                <XAxis.Title>Week</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(usersByWeek))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].reduce((a, b) => ({ exports: a.exports + b.exports }))).map(e => e.exports)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                    </div>}

                    {!isLoading && (showReleases || showAll) &&
                 <div className="chartsContainer">
                    {usersByYear && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" plotOptions={plotOptions} tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Releases by year</Title>
                            <XAxis>
                                <XAxis.Title>Year</XAxis.Title>
                            </XAxis>
                            <YAxis id="number">
                                <YAxis.Title>Exports count</YAxis.Title>
                                <LineSeries id="my-series" name="releases" data={Object.entries(usersByYear).map(e => e[1].reduce((a, b) => ({ releases: a.releases + b.releases }))).map(e => e.releases)} />
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                
                    {usersByMonth && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Releases by month</Title>
                            <Legend />
                            <XAxis categories={monthCategories}>
                                <XAxis.Title>Month</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(usersByMonth))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].reduce((a, b) => ({ releases: a.releases + b.releases }))).map(e => e.releases)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}

                    {usersByWeek && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Releases by week</Title>
                            <Legend />
                            <XAxis categories={weekCategories}>
                                <XAxis.Title>Week</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(usersByWeek))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].reduce((a, b) => ({ releases: a.releases + b.releases }))).map(e => e.releases)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                    </div>}

                    {!isLoading && (showTracks || showAll) &&
                 <div className="chartsContainer">
                    {usersByYear && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" plotOptions={plotOptions} tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Tracks by year</Title>
                            <XAxis>
                                <XAxis.Title>Year</XAxis.Title>
                            </XAxis>
                            <YAxis id="number">
                                <YAxis.Title>Exports count</YAxis.Title>
                                <LineSeries id="my-series" name="tracks" data={Object.entries(usersByYear).map(e => e[1].reduce((a, b) => ({ tracks: a.tracks + b.tracks }))).map(e => e.tracks)} />
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}

                    {usersByMonth && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Tracks by month</Title>
                            <Legend />
                            <XAxis categories={monthCategories}>
                                <XAxis.Title>Month</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(usersByMonth))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].reduce((a, b) => ({ tracks: a.tracks + b.tracks }))).map(e => e.tracks)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}

                    {usersByWeek && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart className="chart" tooltip={tooltip}>
                            <Chart />
                            <Title style={{fontSize:"1.3rem"}}>Tracks by week</Title>
                            <Legend />
                            <XAxis categories={weekCategories}>
                                <XAxis.Title>Week</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(usersByWeek))
                                        .map(e =>
                                            <ColumnSeries key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].reduce((a, b) => ({ tracks: a.tracks + b.tracks }))).map(e => e.tracks)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
            </div>}
        </>
    );
}