import "./App.css";
import { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Release from "./components/release/Release";
import User from "./components/user/User";
import Catalog from "./components/catalog/Catalog";
import Delivered from "./components/delivered/Delivered";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";


function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <BrowserRouter>
      <div>
        <Navbar toggle={toggleSidebar}/>
        <>
        {isOpen && <Sidebar isOpen={isOpen} toggle={toggleSidebar} />}
        </>
          <Switch>
            <Route exact={true} path="/"> <Release />  </Route>
            <Route path="/release"> <Release />  </Route>
            <Route path="/user"> <User /> </Route>
            <Route path="/catalog"> <Catalog /> </Route>
            <Route path="/delivered"> <Delivered /> </Route>
          </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
