import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import KeycloakService from './api/KeyCloack';
import HttpInterceptor from './api/HttpInterceptor';
import Delivered from './components/delivered/Delivered';

const renderApp = () => ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

//const renderApp = () => ReactDOM.render(<App/>, document.getElementById("app"));
const renderUnauthorizedApp = () => ReactDOM.render(<Delivered/>, document.getElementById("root"));

KeycloakService.initKeycloak(renderApp, renderUnauthorizedApp);
HttpInterceptor.configure();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
