import fetchIntercept from "fetch-intercept";
import KeycloakService from "./KeyCloack";

const configure = () => {
    fetchIntercept.register({
        request: function (url, config) {
            const withDefaults = Object.assign({}, config);
            let headers = new Headers();
            if (!url.endsWith("upload")) {
                headers = new Headers({ "Content-Type": "application/json" });
            }

            headers.set(
                'Authorization', `Bearer ${KeycloakService.getToken()}`
            );

            withDefaults.headers = headers;
            return KeycloakService.updateToken(() => {
                return Promise.resolve([url, withDefaults]);
            });
        },

        requestError: function (error) {
            window.location.href = "/#/500";
            return Promise.reject(error);
        },

        response: function (response) {
            if (response.status === 401) {
                window.data = response.headers.get("errorMessage");
                window.location.href = "/#/401";
                return;
            }
            if (response.status === 403) {
                window.data = response.headers.get("errorMessage");
                window.location.href = "/#/403";
                return;
            }
            if (response.status === 404) {
                window.location.href = "/#/404";
            }
            if (response.status === 500) {
                window.location.href = "/#/500";
            }
            if (response.status === 503) {
                window.location.href = "/#/503";
            }
            return response;
        },

        responseError: function (error) {
            window.location.href = "/#/500";
            return new Response(null, {
                status: 500
            });
        }
    });
};
const HttpInterceptor = {
    configure,
};

export default HttpInterceptor;