import React, { useState, useEffect, useRef } from "react";
import Highcharts from 'highcharts/highstock';
import {
    HighchartsProvider, HighchartsChart, Chart, XAxis, YAxis, Title, LineSeries, Legend, ColumnSeries
} from 'react-jsx-highcharts';
import Api from "../../api/Api";
import './Catalog.css'

export default function Catalog() {
    const [cYear, setCYear] = useState(null);
    const [releasesByYear, setReleasesByYear] = useState(null);
    const [releasesByMonth, setReleasesByMonth] = useState(null);
    const [releasesByWeek, setReleasesByWeek] = useState(null);
    const [startYear, setStartYear] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isFetching, setFetching] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const inputFile = useRef(null);

    const [showCYear, setShowCYear] = useState(false);
    const [showReleaseByYear, setShowReleaseByYear] = useState(false);
    const [showReleaseByMonth, setShowReleaseByMonth] = useState(false);
    const [showReleaseByWeek, setShowReleaseByWeek] = useState(false);
    const [showAll, setShowAll] = useState(true);


    useEffect(() => {
        const fetchData = () => {
            Api.get("catalog/c_year/_search")
                .then(cYear => {
                    if (Object.keys(cYear).length > 0) {
                        setCYear(cYear);
                    }
                });
            Api.get("catalog/year/_search")
                .then(releasesByYear => {
                    if (Object.keys(releasesByYear).length > 0) {
                        setStartYear(Number(Object.entries(releasesByYear).map(e => e[0]).shift()));
                        setReleasesByYear(releasesByYear);
                    }
                });
            Api.get("catalog/month/_search")
                .then(releasesByMonth => {
                    if (Object.keys(releasesByMonth).length > 0) {
                        setReleasesByMonth(releasesByMonth);
                    }
                });
            Api.get("catalog/week/_search")
                .then(releasesByWeek => {
                    if (Object.keys(releasesByWeek).length > 0) {
                        setReleasesByWeek(releasesByWeek);
                    }
                });
        }

        if (isFetching) {
            fetchData();
            setFetching(false);
        }
    }, [isFetching]);

    const plotOptions = {
        series: {
            pointStart: startYear
        },
        pie: {
            dataLabels: {
                enabled: true,
                format: '{point.name} : {point.percentage:.1f} %',
            }
        }
    };
    const tooltip = {
        pointFormat: '{series.name}: {point.percentage:.1f} <br>{point.y}'
    }
    const monthCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const weekCategories = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53];


    const selectFile = (event) => {
        setSelectedFiles(event.target.files);
    };

    const upload = () => {
        setLoading(true);
        Api.upload("csv/catalog/upload", selectedFiles[0])
            .then(() => { setSelectedFiles(null); setFetching(true); setLoading(false) });
    }

    function setFilters(clickedId) {
        console.log(clickedId)
        if (clickedId === "cyear") {
            setShowCYear(true);
            setShowReleaseByYear(false);
            setShowReleaseByMonth(false);
            setShowReleaseByWeek(false);
            setShowAll(false)
        } else if (clickedId === "byyear") {
            setShowCYear(false);
            setShowReleaseByYear(true);
            setShowReleaseByMonth(false);
            setShowReleaseByWeek(false);
            setShowAll(false)
        } else if (clickedId === "bymonth") {
            setShowCYear(false);
            setShowReleaseByYear(false);
            setShowReleaseByMonth(true);
            setShowReleaseByWeek(false);
            setShowAll(false)
        } else if (clickedId === "byweek") {
            setShowCYear(false);
            setShowReleaseByYear(false);
            setShowReleaseByMonth(false);
            setShowReleaseByWeek(true);
            setShowAll(false)
        } else if (clickedId === "all") {
            setShowCYear(false);
            setShowReleaseByYear(false);
            setShowReleaseByMonth(false);
            setShowReleaseByWeek(false);
            setShowAll(true) 
        }
     }

    const handleClick = e => setFilters(e.target.id);

    return (
        <>

            <div className="filterMenuContainer">
                <div className="menuItemWrapper">
                        <button id="all" className={showAll ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Show All</button>
                        <button id="cyear" className={showCYear ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>c Year</button>
                        <button id="byyear" className={showReleaseByYear ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Releases by Year</button>
                        <button id="bymonth" className={showReleaseByMonth ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Releases by Month</button>
                        <button id="byweek" className={showReleaseByWeek ? 'menuButtonActive' : "menuButton"} onClick={handleClick}>Releases by Week</button>
                </div>
            <div className="importUploadButtonsContainer">
                <input type="file" ref={inputFile} style={{ display: "none" }} onChange={selectFile} />
                {!isLoading && <button title="Choose File" className="importUploadButton" onClick={() => inputFile.current.click()}><ion-icon style={{pointerEvents:"none"}} name="folder"></ion-icon></button>}
                {!isLoading && <button title="Upload" className="importUploadButton" disabled={!selectedFiles} onClick={upload}><ion-icon style={{pointerEvents:"none"}} name="cloud-upload"></ion-icon></button>}
                {isLoading && <img src='../../Loading_icon.gif' alt="" />}
            </div>
            </div>

            {!isLoading && (showCYear || showAll) &&
            <div className="chartsContainer">
                    {cYear && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart plotOptions={plotOptions} tooltip={tooltip}>
                            <Chart />
                            <Title>c year</Title>
                            <XAxis>
                                <XAxis.Title>Year</XAxis.Title>
                            </XAxis>
                            <YAxis id="number">
                                <YAxis.Title>User count</YAxis.Title>
                                <LineSeries id="my-series" name="c_year" data={Object.entries(cYear).map(e => e[1].length)} />
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>}

                {!isLoading && (showReleaseByYear || showAll) &&
                <div className="chartsContainer">    
                    {releasesByYear && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart plotOptions={plotOptions} tooltip={tooltip}>
                            <Chart />
                            <Title>Releases by year</Title>
                            <XAxis>
                                <XAxis.Title>Year</XAxis.Title>
                            </XAxis>
                            <YAxis id="number">
                                <YAxis.Title>User count</YAxis.Title>
                                <LineSeries id="my-series" name="releases" data={Object.entries(releasesByYear).map(e => e[1].length)} />
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>}

                {!isLoading && (showReleaseByMonth || showAll) &&
            <div className="chartsContainer">
                    {releasesByMonth && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart tooltip={tooltip}>
                            <Chart />
                            <Title>Releases by month</Title>
                            <Legend />
                            <XAxis categories={monthCategories}>
                                <XAxis.Title>Month</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(releasesByMonth))
                                        .map(e =>
                                            <ColumnSeries pointWidth={50} key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].length)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>}

                {!isLoading && (showReleaseByWeek || showAll) &&
            <div className="chartsContainer">
                    {releasesByWeek && <HighchartsProvider Highcharts={Highcharts}>
                        <HighchartsChart tooltip={tooltip}>
                            <Chart />
                            <Title>Releases by week</Title>
                            <Legend />
                            <XAxis categories={weekCategories}>
                                <XAxis.Title>Week</XAxis.Title>
                            </XAxis>
                            <YAxis>
                                {
                                    Object.entries(Object.entries(releasesByWeek))
                                        .map(e =>
                                            <ColumnSeries pointWidth={50} key={e[1][0]} name={e[1][0]} data={Object.entries(e[1][1]).map(d => d[1].length)} />
                                        )
                                }
                            </YAxis>
                        </HighchartsChart>
                    </HighchartsProvider>}
                </div>}
        </>
    );
}